import { useState } from 'react'

export default function (state) {
    const [values, setValues] = useState(state)
    const [errors, setErrors] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const createExceptionHandler = (f) => (e) => e && e.errors && setErrors(f(e.errors))

    const defaultExceptionHandler = createExceptionHandler((errors) => errors)

    async function submit(submitHandler, errorHandler = defaultExceptionHandler) {
        try {
            setIsLoading(true)

            await submitHandler()
        } catch (e) {
            errorHandler(e)
        } finally {
            setIsLoading(false)
        }
    }

    return {
        submit,
        isLoading,
        setIsLoading,
        createExceptionHandler,
        values,
        setValues,
        errors,
        setErrors,
        input(field) {
            return {
                value: values[field],
                onChange(value) {
                    setValues({ ...values, [field]: value })
                },
                error: errors && errors[field],
                setError(value) {
                    setErrors({ ...errors, [field]: value })
                },
            }
        },
        select(field) {
            return {
                value: values[field],
                onChange(value) {
                    setValues({ ...values, [field]: value })
                },
                error: errors && errors[field],
                setError(value) {
                    setErrors({ ...errors, [field]: value })
                },
            }
        },
        checkbox(field) {
            return {
                checked: values[field],
                setChecked() {
                    let value = values[field] ? 0 : 1
                    setValues({ ...values, [field]: value })
                },
                error: errors && errors[field],
                setError(value) {
                    setErrors({ ...errors, [field]: value })
                },
            }
        },
        radio(field) {
            return {
                value: values[field],
                onChange(label) {
                    setValues({ ...values, [field]: label })
                },
                error: errors && errors[field],
                setError(value) {
                    setErrors({ ...errors, [field]: value })
                },
            }
        },
        array(field, idx) {
            return {
                value: values[field][idx],
                onChange(value) {
                    setValues({
                        ...values,
                        [field]: [...values[field].slice(0, idx), value, ...values[field].slice(idx + 1)],
                    })
                },
                error: errors && errors[field],
                setError(value) {
                    setErrors({ ...errors, [field]: value })
                },
            }
        },
        objArray(obj, field, idx) {
            return {
                value: values[obj][idx][field],
                onChange(value) {
                    setValues({
                        ...values,
                        [obj]: [
                            ...values[obj].slice(0, idx),
                            { ...values[obj][idx], [field]: value },
                            ...values[obj].slice(idx + 1),
                        ],
                    })
                },
                error: errors && errors[obj],
                setError(value) {
                    setErrors({ ...errors, [obj]: value })
                },
            }
        },
        checkboxArray(field, id) {
            return {
                checked: values[field].includes(id),
                setChecked(id) {
                    setValues({
                        ...values,
                        [field]: values[field].includes(id)
                            ? values[field].filter((item) => item !== id)
                            : [...values[field], id],
                    })
                },
                error: errors && errors[field],
                setError(value) {
                    setErrors({ ...errors, [field]: value })
                },
            }
        },
        arrayLength(field) {
            return values[field].length
        },
        arrayRemove(field, idx) {
            setValues({
                ...values,
                [field]: [...values[field].slice(0, idx), ...values[field].slice(idx + 1)],
            })
        },
        arrayPush(field, initial) {
            setValues({ ...values, [field]: [...values[field], initial ? initial : ''] })
        },
    }
}
