import getConfig from 'next/config'
import axios from 'axios'
import qs from 'qs'
import cookies from './cookies'

const { publicRuntimeConfig } = getConfig()

const fullUrl = (url) => `${publicRuntimeConfig.apiUrl}/${url}`

const generateKey = (base, params = {}) => {
    let key = `/${base}`
    const paramsKeys = Object.keys(params)
    paramsKeys.length > 0 &&
        paramsKeys.forEach((item, ind) => {
            if (ind === 0) {
                key = `${key}?${item}=${params[item]}`
            } else {
                key = `${key}&${item}=${params[item]}`
            }
        })

    return key
}

const isClient = () => typeof window !== 'undefined'

export function createApi(tokenExtractor, langExtractor) {
    const getHeaders = () => {
        const token = tokenExtractor()
        const lang = langExtractor()
        const headers = {}

        if (token) {
            headers['Authorization'] = `Bearer ${token}`
        }
        if (lang) {
            headers['Accept-Language'] = lang
        }

        return headers
    }

    let cacheData = {}

    function fillCache(data) {
        cacheData = { ...data }
    }

    function extractCache() {
        return { ...cacheData }
    }

    function toFormData(obj, form, namespace) {
        let fd = form || new FormData()
        let formKey

        for (let property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (namespace) {
                    formKey = namespace + '[' + property + ']'
                } else {
                    formKey = property
                }

                if (obj[property] instanceof Date) {
                    fd.append(formKey, obj[property].toISOString())
                } else if (
                    typeof obj[property] === 'object' &&
                    obj[property] !== null &&
                    !(obj[property] instanceof File)
                ) {
                    toFormData(obj[property], fd, formKey)
                } else {
                    fd.append(formKey, obj[property] === null ? '' : obj[property])
                }
            }
        }

        return fd
    }

    const get = (url, shouldCache = true) => (query = {}) => {
        const { cancelToken = axios.CancelToken.source().token, ...dirtyParams } = query

        const params = {}

        Object.keys(dirtyParams).forEach((key) => {
            let value = dirtyParams[key]
            if (key.includes('[]')) {
                key = key.replace('[]', '')
                value = Array.isArray(value) ? value : [value]
            }
            params[key] = value
        })

        const key = generateKey(url, params)

        if (isClient() && cacheData[key]) {
            // console.warn('return from cache: ', key)
            return cacheData[key]
        }

        return axios
            .get(fullUrl(url), {
                cancelToken,
                params,
                paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'indices' }),
                headers: getHeaders(),
            })
            .then((response) => {
                if (shouldCache) {
                    // console.warn('write in cache: ', key)
                    cacheData[key] = response.data
                }
                return response.data
            })
            .catch((err) => {
                if (axios.isCancel(err)) {
                    return
                } else {
                    if (err.response.status === 404) {
                        const error = new Error(`Not Found: ${err.response.config.url}`)
                        error.statusCode = 404
                        error.url = err.response.config.url
                        error.params = err.response.config.params
                        error.method = 'get'
                        throw error
                    } else {
                        const error = new Error(`Internal Error: ${err.response.config.url}`)
                        error.statusCode = 500
                        error.url = err.response.config.url
                        error.params = err.response.config.params
                        error.method = 'get'
                        throw error
                    }
                }
            })
    }

    const post = (url, type = 'json') => (data) => {
        return axios
            .post(fullUrl(url), type == 'form' ? toFormData(data) : data, { headers: getHeaders() })
            .then((response) => response.data)
            .catch((err) => {
                if (err.response.status === 404) {
                    const error = new Error(`Not Found: ${err.response.config.url}`)
                    error.statusCode = 404
                    error.url = err.response.config.url
                    error.params = err.response.config.data
                    error.method = 'post'
                    throw error
                } else {
                    const error = new Error(`Internal Error: ${err.response.config.url}`)
                    error.statusCode = 500
                    error.url = err.response.config.url
                    error.params = err.response.config.params
                    error.method = 'post'
                    if (err.response.data.errors) {
                        error.errors = err.response.data.errors
                    } else if (err.response.data.data) {
                        error.errors = err.response.data.data
                    }
                    throw error
                }
            })
    }

    return {
        master: (id) => get(`masters/web/${id}`, false),
        days: get('client/orders/days-with-free-time', false),
        availableTime: get('client/orders/available-time', false),

        auth: {
            sendCode: post('auth/send-code'),
            register: post('auth/register-web'),
        },

        order: post('client/orders'),
        orderInfo: (id) => get(`client/orders/show/${id}`),

        fillCache,
        extractCache,
    }
}

export default createApi(
    () => cookies.get('es_token'),
    () => cookies.get('es_lang'),
)
