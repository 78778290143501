import React, { useContext, useState, useEffect } from 'react'
import useForm from '@/lib/hooks/form'
import { useLocale } from '@/contexts/LocaleContext'
import api from '@/lib/api'

const OrderContext = React.createContext()

export function OrderProvider({ children, master }) {
    // const [master, setMaster] = useState(initialMaster)
    // const { locale } = useLocale()

    const form = useForm({ services: [], date: '', time: '', first_name: '', phone: '', code: '' })

    // const fetchMaster = async () => {
    //     const { data } = await api.master(initialMaster.id)({set_default_locale: false})
    //     setMaster(data)
    // }
    //
    // useEffect(() => {
    //     fetchMaster()
    // }, [locale])

    return <OrderContext.Provider value={{ form, master }}>{children}</OrderContext.Provider>
}

export function useOrder() {
    return useContext(OrderContext)
}
